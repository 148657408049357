import { FLAG } from './_utils.js';
export function anchorLink() {
  var urlHash = location.hash;
  $('body,html').stop().scrollTop(0);
  setTimeout(function () {
    if (urlHash) {
      if (FLAG) {
        var position = $(urlHash).offset().top;
      } else {
        var position = $(urlHash).offset().top;
      }

      $('body,html').stop().animate({
        scrollTop: position - $('.p-header_head').innerHeight()
      }, 500);
    }
  }, 500);
  $("a[href*='#'], a[href='']").on("click", function () {
    var href = $(this).attr("href").replace('/', '');
    var target = $(href == "#" || href == "" ? "html" : href);
    var position = target.offset().top;
    $("html, body").animate({
      scrollTop: position - $('.p-header_head').innerHeight()
    }, 500);
  });
}