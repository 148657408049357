var MEDIA_QUERY = window.matchMedia("screen and (max-width: 63.75em)");
var FLAG = MEDIA_QUERY.matches;
MEDIA_QUERY.addListener(function () {
  FLAG = MEDIA_QUERY.matches;
});
export function accordion() {
  if (FLAG) {
    $('.js-accordion_btn').on('click', function () {
      $(this).closest('.js-accordion').find('.js-accordion_item').slideToggle();
      $(this).toggleClass('is-active');
    });
  }
}