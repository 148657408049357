/* -----------------------------------
  MODULES
------------------------------------ */
import { WIN_WIDTH } from './modules/_utils.js';
import { imgSwitch } from './modules/_imgSwitch.js';
import { slider } from './modules/_slider.js';
import { pulldown } from './modules/_pulldown.js';
import { menuBtn } from './modules/_menuBtn.js';
import { txtWrapping } from './modules/_txtWrapping.js';
import { accordion } from './modules/_accordion.js';
import { intro } from './modules/_intro.js';
import { tooltip } from './modules/_tooltip.js';
import { anchorLink } from './modules/_anchorLink.js';
var CURRENT_WIDTH = WIN_WIDTH;
window.addEventListener('DOMContentLoaded', function () {
  function pageFunc() {
    var pageId = $('main').attr('id');
    var pageEvent = {
      top: function top() {
        slider();
        txtWrapping();
        intro();
      },
      shops: function shops() {},
      common: function common() {
        window.addEventListener('resize', function () {
          if (CURRENT_WIDTH !== WIN_WIDTH) {
            CURRENT_WIDTH = WIN_WIDTH;
            imgSwitch();
          }
        });
        imgSwitch();
        pulldown();
        menuBtn();
        accordion();
        tooltip();
        anchorLink();
      }
    };
    pageEvent.common();

    switch (pageId) {
      case 'top':
        pageEvent.top();
        break;

      case 'shops':
        pageEvent.shops();
    }
  }

  pageFunc();
});