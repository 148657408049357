import { FLAG } from './_utils.js';
export function imgSwitch() {
  var target = document.getElementsByClassName('js-img_switch');

  for (var i = 0; i < target.length; i++) {
    if (target[i].getAttribute('src')) {
      var attr = 'src';
    } else {
      var attr = 'data-src';
    }

    ;

    if (FLAG) {
      var src = target[i].getAttribute(attr).replace('_pc.', '_sp.');
    } else {
      var src = target[i].getAttribute(attr).replace('_sp.', '_pc.');
    }

    target[i].setAttribute(attr, src);
  }
}