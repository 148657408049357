export function intro() {
  var tl = new TimelineMax();
  TweenMax.set('.p-header', {
    y: -$('.p-header').innerHeight() - $('.p-header').offset().top,
    opacity: 0
  });
  tl.to('.l-kv_slider', 1, {
    opacity: 1,
    x: 0,
    ease: Quad.easeInOut
  }, '+=.5').to('.c-txt_wrapping', 1, {
    y: 0,
    ease: Quad.easeInOut
  }, '-=.2').to('.p-header', 1, {
    y: 0,
    opacity: 1,
    ease: Quad.easeInOut,
    onComplete: function onComplete() {
      $('.js-slider').slick('slickNext');
      $('.js-slider').slick('slickPlay');
    }
  }, "-=1");
}