export function slider() {
  var props = {
    dots: true,
    arrows: false,
    autoplay: false,
    autoplaySpeed: 3000,
    speed: 850,
    slidesToShow: 1,
    infinite: true,
    cssEase: 'cubic-bezier(.455, .03, .515, .955)'
  };
  $('.js-slider').slick(props);
  $('.js-slider').slick('slickPause');
}