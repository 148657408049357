var WIN_WIDTH = window.innerWidth;
var WIN_HEIGHT = window.innerHeight;
var SCROLL_TOP = window.pageYOffset || document.documentElement.scrollTop;
var MEDIA_QUERY = window.matchMedia("screen and (max-width: 48em)");
var FLAG = MEDIA_QUERY.matches;
window.addEventListener('scroll', function () {
  SCROLL_TOP = window.pageYOffset;
});
window.addEventListener('resize', function () {
  WIN_WIDTH = window.innerWidth;
  WIN_HEIGHT = window.innerHeight;
});
MEDIA_QUERY.addListener(function () {
  FLAG = MEDIA_QUERY.matches;
});
export { WIN_WIDTH, WIN_HEIGHT, SCROLL_TOP, FLAG };