var MEDIA_QUERY = window.matchMedia("screen and (max-width: 63.75em)");
var FLAG = MEDIA_QUERY.matches;
MEDIA_QUERY.addListener(function () {
  FLAG = MEDIA_QUERY.matches;
});
export function pulldown() {
  if (!FLAG) {
    $('.js-pulldown, .js-pulldown_item').hover(function () {
      $('.js-pulldown_item').addClass('is-active');
    }, function () {
      $('.js-pulldown_item').removeClass('is-active');
    });
  }
}